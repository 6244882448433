:root {
  font: 16px / 1 sans-serif;
  --primary-background: #1eaf9c;
  --secondary-background: #f1f7f1;
  --accent: #63707e;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

nav {
  position: relative;
  background-color: var(--accent);
  color: white;
  padding: 0em 2em 0em 0em;
}

nav,
ul,
a {
  font-size: 1.1em !important;
}

.about-me-header {
  color: var(--secondary-background);
  font-family: "Milonga", cursive;
  text-align: center;
  padding: 1em 1em 0.2em 1em;
  background-color: var(--accent);
}

.header {
  background-color: var(--primary-background);
  color: var(--secondary-background);
  font-family: "Milonga", cursive;
  text-align: center;
  padding: 2em 1em 0.2em 1em;
}

/* h2 {
  font-weight: 500;
} */

.row {
  display: flex;
  flex-direction: row;
}

.responsive-img {
  width: 25em;
}

.img-style {
  display: flex;
  cursor: pointer;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--primary-background);
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  width: 22em;
}

.card:hover {
  transform: scale(1.1);
}

.card-title {
  font-size: 24px;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.card-action {
  display: flex;
  border-radius: 0.5em;
}

/* spacing for card buttons */
.card-action > .waves-light {
  margin: 0.5em;
  width: 8em;
}

.card-image {
  max-width: 100%;
  max-height: 16em;
  border-radius: 0.5em;
}

.card-content {
  height: 7.5em;
}

strong {
  font-weight: 600;
}

.card-description {
  font-size: 1em;
  padding-top: 1em;
}

/* work section */
.work-container {
  background-color: var(--primary-background);
  padding-left: 2em;
}

.work-history-container {
  padding-top: 2em;
}

/*left side of work history */
.work-history-header {
  padding-left: 4em;
  height: 3vw;
}

/*right side of work history */
.work-logo-container {
  display: flex;
  position: relative;
  bottom: 4em;
  padding-right: 6em;
  justify-content: flex-end;
}

.work-logo {
  width: 15em;
}

.work-accomplishments {
  padding-top: 2em;
  list-style-type: circle;
  margin: 0em;
}

.work-list {
  list-style-type: circle !important;
  font-size: 1.25em;
}

.skills-container {
  display: flex;
  background-color: var(--primary-background);
  font-weight: 600;
  color: var(--secondary-background);
}

/* #slack {
  margin-top:1.4em;
} */

a {
  color: var(--secondary-background);
}

.row {
  display: flex;
  justify-content: center;
  padding: 0.5em;
}

.col {
  flex: 1 0;
}

.logo {
  width: 5em;
  height: 5em;
  /* add background color for transparent logos */
  background-color: rgb(32, 30, 30);
}

.skill {
  padding: 0.5em;
}

#az {
  padding: 0.5em;
}

.about-me {
  display: flex;
  /* list-style: none; */
  background-color: var(--accent);
  padding: 2em;
  font-size: 1.25em;
  color: var(--secondary-background);
  line-height: 1.25em;
}

.about-container {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
}

.download-resume {
  padding-top: 1em;
}

.contact-header {
  display: flex;
  justify-content: center;
  font-family: "Milonga", cursive;
  font-weight: 600;
  color: var(--accent);
}

.contact-form {
  padding: 1.5em 1.5em 6em 1.5em;
  background-color: var(--secondary-background);
}

.input-field {
  font-size: 1.25em;
}

textarea {
  height: 4em;
}

.message-btn {
  margin-top: 1em;
  font-size: 1.1em;
  border-radius: 0.5em;
  padding: 0.75em 0.75em 2em 0.75em;
  text-transform: capitalize;
}

/* show screen readers only */
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.copyright {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  font-size: 1em;
  color: white;
  z-index: 999;
  font-weight: 550;
}

.linkedin,
.github,
.codewars,
.leetcode {
  position: relative;
  padding: 0.2em;
  padding-right: 0.4em;
  height: 42px;
  width: 42px;
  border-radius: 0.9em;
}

.fab-container {
  position: fixed;
  bottom: 10px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
}

.fab-icon-holder {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.fab-icon-holder:hover {
  opacity: 0.8;
}

.fab-icon-holder i {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 100%;
  font-size: 40px;
  border-radius: 2em;
  background-color: var(--accent);
}

.fab-icon {
  background-color: var(--accent);
  padding-left: 0.3em;
  padding-top: 0.2em;
}

.fab {
  width: 60px;
  height: 60px;
}

.fab-options {
  position: absolute;
  list-style-type: none;
  bottom: 70px;
  right: 0;
  opacity: 0;
  margin: 0;
}

.fab-options li {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.fab-label {
  padding: 2px 5px;
  align-self: center;
  user-select: none;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 16px;
  margin-right: 10px;
}

.fab-label:hover {
  font-weight: bold;
}

.fab:hover + .fab-options,
.fab-options:hover {
  opacity: 1;
}

.page-footer-container {
  position: relative;
  background-color: var(--primary-background);
}

.wave-container {
  position: relative;
  height: 4em;
}

.copyright > p {
  z-index: 999;
}

.wave {
  position: absolute;
  bottom: 0;
  height: 140px;
  width: 100%;
  background: url("./components/images/wave.png") repeat-x;
  background-size: 250rem 30rem;
  animation: animate 15s linear infinite;
}

@keyframes animate {
  0% {
    background-position-x: 0;
    background-position-x: -250rem;
  }
}

.wave:nth-child(1) {
  opacity: 0.9;
}

.wave:nth-child(2) {
  opacity: 0.5;
  animation-delay: 0.2s;
  animation-direction: reverse;
}

.wave:nth-child(3) {
  opacity: 0.7;
  animation-delay: 5s;
}

/* iPhone 5/SE */
@media (max-width: 320px) and (max-height: 568px) {
  nav > ul > li {
    width: 4.5em;
  }
}

/*Galaxy S9+*/
@media (max-width: 320px) and (max-height: 658px) {
  nav > ul > li {
    display: flex;
    justify-content: center;
    position: relative;
    width: 4.5em;
  }
}

/*blackberry Z30*/
@media (max-width: 360px) and (max-height: 640px) {
  nav {
    padding-left: 1em;
  }
  .card {
    width: 19em;
  }
  .fab-container {
    display: none;
  }
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    padding: 2em;
  }
}

/* iPhone X */
@media (max-width: 375px) and (max-height: 812px) {
  nav {
    display: flex;
    width: 48em;
  }
}

/* Galaxy S5 */
@media (max-width: 360px) and (max-height: 640px) {
  nav > ul > li {
    font-size: 0.75em;
  }

  .profile-pic-container {
    display: none;
  }

  .about-container {
    line-height: 1em;
  }
}

/* samsung galaxy S8+ */
@media (max-width: 360px) and (max-height: 740px) {
  nav > ul > li {
    padding-left: 0.6em;
    font-size: 0.75em;
  }
  .card {
    width: 18em;
  }
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    padding: 2em;
  }
  .profile-pic-container {
    display: none;
  }
}

/* iPhone 12 Pro */
@media (max-width: 390px) and (max-height: 844px) {
  nav > ul > li {
    display: flex;
    position: relative;
    justify-content: center;
    width: 4.5em;
  }
}

/* Nokia N9 */
@media (max-width: 480px) and (max-height: 854px) {
  .skills-container {
    padding: 6em;
    display: flex;
    flex-wrap: wrap;
  }
  .profile-pic-container {
    display: none;
  }
  .fab-container {
    display: none;
  }
}

/* hide go back up button for more screens */
@media (max-width: 1280px) and (max-height: 1280px) {
  .go-back-up-container {
    display: none;
  }
}

/*hide profile pic on smaller screens*/
@media (max-width: 1280px) and (max-height: 1280px) {
  .profile-pic-container {
    display: none;
  }
}
